<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <img
          src="@/assets/images/gif/progress.gif"
          class="img-fluid"
          alt="logo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'CallbackGoogle',
  data () {
    return {
      globalUserObject: {}
    };
  },
  mixins: [goToUserProfile, userDataMixin],
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  mounted () {
    this.getGoogleResponse();
  },
  created () {
    this.setImportedConstants();
  },
  methods: {
    getGoogleResponse () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code');
      const scope = urlParams.get('scope');
      const prompt = urlParams.get('prompt');
      const authuser = urlParams.get('authuser');
      this.backendCallback(authuser, code, scope, prompt);
    },
    sendDataSignInLayerInfo () {
      this.trackUserData('sign_in', {
        register_type: 'google'
      })
    },
    sendDataSignUpLayerInfo () {
      this.trackUserData('sign_up', {
        register_type: 'google'
      })
    },
    ...mapActions({
      signInProvider: 'auth/signInProvider'
    }),
    createJob (selectedService, startDate) {
      const formData = {
        prosker_user_id: selectedService.user_id,
        client_user_id: this.currentUser.id,
        title: selectedService.name,
        description: selectedService.description,
        prosker_approval: null,
        client_approval: null,
        price: selectedService.price,
        start_date: startDate,
        end_date: selectedService.end_date || null,
        payment_required: selectedService.payment_required,
        reason_help: null,
        service_id: selectedService.id
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/service/${selectedService.id}/create-job`;
      return axios
        .post(url, formData)
        .then(() => {
          console.log('Job creado exitosamente');
          return true;
        })
        .catch((error) => {
          console.error('Error creating the job:', error);
          throw error;
        });
    },
    async backendCallback (authUser, code, scope, prompt) {
      await axios
        .get(process.env.VUE_APP_BASE_URL + '/api/login/google/callback', {
          params: {
            code: code,
            scope: scope,
            authuser: authUser,
            prompt: prompt
          }
        })
        .then(async (response) => {
          await this.signInProvider(response.data.token);
          if (response.data.isNewUser) {
            this.sendDataSignUpLayerInfo();
          } else {
            this.sendDataSignInLayerInfo();
            const selectedService = this.$route.query.selectedService;
            const selectedServiceId = selectedService?.id;
            const startDate = this.$route.query.startDate;
            if (selectedService) {
              if (selectedService.payment_required === 0) {
                return this.createJob(selectedService, startDate)
                  .then(() => {
                    this.$swal.fire({
                      title: this.$t('awesome'),
                      text: this.$t('jobCreatedSuccessfully'),
                      confirmButtonColor: '#19AD93'
                    });
                    this.$router.push({ name: 'social.services' });
                  });
              } else {
                this.$router.push({
                  name: 'checkout-service',
                  query: { start_date: startDate, selectedServiceId: selectedServiceId }
                });
                return;
              }
            }
          }
          this.goToUserProfile(this.currentUser);
        }).catch(() => {
          this.$swal.fire({
            text: this.$t('loginGoogleErrorMessage')
          }).then(() => {
            this.$router.push({ name: 'auth1.sign-in1' });
          });
        })
    }
  }
};
</script>
<style>
.size {
  height: 900px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
