<template>
    <v-container>
      <v-row class="mx-1">
        <v-col cols="12" md="6">
          <h1 class="green-prosk">PROSK</h1>
        </v-col>
      </v-row>
      <v-row class="d-md-none d-block mx-1">
        <v-col cols="12" class="bg-light-grey">
          <v-row>
            <v-col cols="6" class="p-0">
              <strong>{{ $t("total") }}: </strong>
            </v-col>
            <v-col cols="6" class="p-0 text-right" v-if="service">
              <money-format
                :value="service.price"
                disabled
                :currency-code="currencyCode"
                :locale="$i18n.locale"
                :supplemental-precision="0"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="6">
          <v-row>
            <v-expansion-panels v-model="panel" accordion mandatory>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("payForm") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-3 pb-5">
                    <v-row>
                      <v-col cols="12" align="center" justify="center">
                        <v-avatar>
                          <img
                            v-if="!this.currentUser"
                            src="@/assets/images/page-img/avatar.png"
                            alt="user"
                            class="avatar-60"
                          />
                          <img
                            v-if="this.currentUser && !this.currentUser.profile_img"
                            src="@/assets/images/page-img/avatar.png"
                            alt="user"
                            class="avatar-60"
                          />
                          <img
                            v-if="this.currentUser && this.currentUser.profile_img"
                            :src="this.currentUser.profile_img"
                            class="rounded-circle avatar-60"
                            alt="user"
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                    <v-row>
                      <input
                        type="hidden"
                        id="mercado-pago-public-key"
                        th:value="${publicKey}"
                      />
                      <form id="form-checkout-service">
                        <v-row>
                          <!--<input id="form-checkout-service__cardholderName" name="cardholderName" type="text" class="form-control"/>-->
                          <v-col :cols="!this.currentUser ? 6 : 12">
                            <v-text-field
                              id="form-checkout-service__cardholderName"
                              name="cardholderName"
                              type="text"
                              outlined
                              color="#59D79D"
                              :label="$t('name')"
                              :hide-details="nameError.length === 0"
                              :error-count="nameError.length"
                              :error-messages="nameError"
                              v-model="name"
                            ></v-text-field>
                          </v-col>
                          <!--<input id="form-checkout-service__cardholderEmail" name="cardholderEmail" type="email" class="form-control"/>-->
                          <v-col :cols="12">
                            <v-text-field
                              id="form-checkout-service__cardholderEmail"
                              name="cardholderEmail"
                              type="email"
                              :label="$t('email')"
                              outlined
                              color="#59D79D"
                              :hide-details="emailError.length === 0"
                              :error-count="emailError.length"
                              :error-messages="emailError"
                              v-model="email"
                            ></v-text-field>
                          </v-col>
                          <!--<input id="form-checkout-service__cardNumber" name="cardNumber" type="text" class="form-control"/>-->
                          <v-col cols="12">
                            <v-text-field
                              :label="$t('creditCard')"
                              outlined
                              color="#59D79D"
                              @keypress="onlyNumber"
                              id="form-checkout-service__cardNumber"
                              type="text"
                              name="cardNumber"
                              :hide-details="creditCardError.length === 0"
                              :error-count="creditCardError.length"
                              :error-messages="creditCardError"
                              v-model="creditCard"
                            ></v-text-field>
                          </v-col>
                          <!--<input id="form-checkout-service__cardExpirationMonth" name="cardExpirationMonth" type="text" class="form-control"/>-->
                          <v-col cols="6">
                            <v-text-field
                              :label="$t('expirationMonth')"
                              outlined
                              color="#59D79D"
                              @keypress="onlyNumber"
                              :maxlength="max"
                              id="form-checkout-service__cardExpirationMonth"
                              name="cardExpirationMonth"
                              type="text"
                              :hide-details="expirationMonthError.length === 0"
                              :error-count="expirationMonthError.length"
                              :error-messages="expirationMonthError"
                              v-model="expirationMonth"
                            ></v-text-field>
                          </v-col>
                          <!--<input id="form-checkout-service__cardExpirationYear" name="cardExpirationYear" type="text" class="form-control"/>-->
                          <v-col cols="6">
                            <v-text-field
                              :label="$t('expirationYear')"
                              outlined
                              color="#59D79D"
                              @keypress="onlyNumber"
                              :maxlength="max"
                              id="form-checkout-service__cardExpirationYear"
                              name="cardExpirationYear"
                              type="text"
                              :hide-details="expirationYearError.length === 0"
                              :error-count="expirationYearError.length"
                              :error-messages="expirationYearError"
                              v-model="expirationYear"
                            ></v-text-field>
                          </v-col>
                          <!--<input id="form-checkout-service__securityCode" name="securityCode" type="text" class="form-control"/>-->
                          <v-col :cols="12">
                            <v-text-field
                              :label="$t('securityCode')"
                              outlined
                              color="#59D79D"
                              id="form-checkout-service__securityCode"
                              name="securityCode"
                              type="text"
                              maxlength="3"
                              :hide-details="securityCodeError.length === 0"
                              :error-count="securityCodeError.length"
                              :error-messages="securityCodeError"
                              v-model="securityCode"
                            ></v-text-field>
                          </v-col>
                          <!--<select id="form-checkout-service__installments" name="installments" type="text" class="form-control"></select>-->
                          <v-col cols="6" class="d-none">
                            <select
                              id="form-checkout-service__installments"
                              name="installments"
                              class="select-css"
                            ></select>
                          </v-col>
                          <!--<select id="form-checkout-service__identificationType" name="identificationType" class="form-control"></select>-->
                          <v-col cols="6">
                            <select
                              id="form-checkout-service__identificationType"
                              name="identificationType"
                              class="select-css"
                            ></select>
                          </v-col>
                          <!--<input id="form-checkout-service__identificationNumber" name="docNumber" type="text" class="form-control"/>-->
                          <v-col cols="6">
                            <v-text-field
                              :label="$t('documentNumber')"
                              outlined
                              color="#59D79D"
                              id="form-checkout-service__identificationNumber"
                              name="identificationNumber"
                              type="text"
                              :hide-details="documentNumberError.length === 0"
                              :error-count="documentNumberError.length"
                              :error-messages="documentNumberError"
                              v-model="identificationNumber"
                            ></v-text-field>
                          </v-col>
                          <!--<select id="form-checkout-service__issuer" name="issuer" class="form-control"></select>-->
                          <select
                            id="form-checkout-service__issuer"
                            name="issuer"
                            class="d-none"
                          ></select>
                          <input type="hidden" id="amount" />
                          <input type="hidden" id="description" />
                          <v-col cols="12">
                            <general-button
                              :loading="load"
                              :action="nextStep"
                              class="white--text"
                              block
                            >
                              {{ $t("next") }}
                            </general-button>
                            <button
                              id="form-checkout-service__submit"
                              type="submit"
                              class="d-none"
                            ></button>
                          </v-col>
                        </v-row>
                      </form>
                    </v-row>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel :disabled="cardDataDisabled">
                <v-expansion-panel-header>
                  {{ $t("contactInformation") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pb-5">
                    <v-row>
                      <v-col
                        cols="12"
                        align="center"
                        justify="center"
                        class="mb-3"
                      >
                        <v-avatar>
                          <img
                            v-if="
                              this.currentUser && !this.currentUser.profile_img
                            "
                            src="@/assets/images/page-img/avatar.png"
                            alt="user"
                            class="avatar-60"
                          />
                          <img
                            v-if="
                              this.currentUser && this.currentUser.profile_img
                            "
                            :src="this.currentUser.profile_img"
                            class="rounded-circle avatar-60"
                            alt="user"
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-row>
                        <v-col cols="3" class="pr-0">
                          <v-autocomplete
                            :items="phoneCodes"
                            :label="$t('countryCode')"
                            prefix="+"
                            outlined
                            :hide-details="phoneCodeError.length === 0"
                            :error-count="phoneCodeError.length"
                            :error-messages="phoneCodeError"
                            item-text="code"
                            item-value="code"
                            return-object
                            v-model="phoneInternationalInfos"
                            color="#59D79D"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <flag :iso="data.item.country" />
                                  <span class="pl-2"> {{ data.item.code }}</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            :label="$t('phoneNumber')"
                            outlined
                            color="#59D79D"
                            name="cardPhone"
                            @keypress="onlyNumber"
                            id="form-checkout-service__cardPhone"
                            :counter="phoneInternationalInfos.phoneMask.length"
                            :maxlength="phoneInternationalInfos.phoneMask.length"
                            :hide-details="phoneError.length === 0"
                            :error-count="phoneError.length"
                            :error-messages="phoneError"
                            v-model="phone"
                            v-mask="phoneInternationalInfos.phoneMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="6">
                              {{ $t("myAddresses") }}
                            </v-col>
                            <v-col cols="6">
                              <user-addresses-dialog
                                :item="editedItem"
                                :on-close="onAddClose"
                                :new-item="newItem"
                                :show-add="showAdd"
                              />
                            </v-col>
                            <div v-if="this.currentUser && this.currentUser.addresses">
                              <v-col cols="12" v-for="address in currentUser.addresses" :key="address.id">
                                <v-card>
                                  <v-card-text class="py-2">
                                    <strong>{{ $t("domicile") }}</strong><br><br>
                                    {{ address.line_1 }}<br>
                                    {{ address.neighborhood }}<br>
                                    {{ address.city.name }}<br>
                                    {{ address.state ? address.state : ''  }} {{ address.postal_code }}<br>
                                    <strong v-if="!address.is_default">{{ $t("isDefault") }}</strong>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-col
                                      cols="6"
                                      class="p-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-btn
                                        icon
                                        color="secondary"
                                        @click="editItem(address)"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="p-0">
                                      <v-btn
                                        @click="selectAddress(address)"
                                        block
                                        color="#59D79D"
                                        class="white--text float-right"
                                      >
                                        <v-icon v-if="address.selected"
                                          >mdi-check</v-icon
                                        >
                                        <v-icon v-else>mdi-gesture-tap</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="payOrder"
                            :loading="loading"
                            :disabled="!canContinue"
                            color="#59D79D"
                            class="white--text"
                            block
                          >
                            {{ $t("pay") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-col>
        <v-col md="6" class="d-none d-md-block p-3 bg-prosk-secondary" v-if="service">
          <v-row>
            <v-col cols="3">
              <strong class="green-prosk">{{ $t("description") }}: </strong>
            </v-col>
            <v-col cols="9">
              <span class="">{{ service.description }}</span>
            </v-col>
            <v-col cols="3" v-if="!!selectedDateTime.date">
              <strong class="green-prosk">{{ $t("startDate") }}: </strong>
            </v-col>
            <v-col cols="9" v-if="!!selectedDateTime.date">
             <span class="">{{ getDateFormat(selectedDateTime.date) }} {{ selectedDateTime.time }}</span>
            </v-col>
            <v-col cols="3">
              <strong class="green-prosk">{{ $t("total") }}: </strong>
            </v-col>
            <v-col cols="9">
              <money-format
                v-if="service"
                :value="service.price"
                disabled
                class=""
                :currency-code="currencyCode"
                :locale="$i18n.locale"
                :supplemental-precision="0"
              />
              <p id="total-cost" class="d-none">{{ service.price }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import MoneyFormat from 'vue-money-format';
  import axios from 'axios';
  import moment from 'moment';
  import { mask } from 'vue-the-mask';
  import { PhoneNumberUtil } from 'google-libphonenumber';
  import UserAddressesDialog from '@/components/socialvue/dialogs/UserAddressesDialog.vue';
  import errorDialog from '@/components/socialvue/mixins/errorDialog';
  import { getPhoneCodes, validateMonth, typeOnlyNumber, removeMaskFromPhoneNumber } from '@/misc/utils';
  import { AES_SECRET } from '@/misc/constants';
  import userDataMixin from '@/mixins/userDataMixin';
  import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

  const phoneCodes = getPhoneCodes();
  const defaultPhoneInternationalInfos = phoneCodes[1];

  export default {
    name: 'ServiceCheckout',
    props: ['service', 'selectedDateTime'],
    directives: { mask },
    components: { UserAddressesDialog, MoneyFormat, GeneralButton },
    mixins: [errorDialog, userDataMixin],
    data () {
      return {
        currencyCode: '',
        job: null,
        subscription: null,
        mpPublicKey: '',
        totalCost: 0,
        searchPhoneCodesInput: '',
        phoneCodes: phoneCodes,
        phoneInternationalInfos: defaultPhoneInternationalInfos,
        phoneCodeError: [],
        lastNameError: [],
        userNameError: [],
        birthDateError: [],
        documentTypeError: [],
        paymentMethodError: [],
        documentNumberError: [],
        emailError: [],
        expirationYearError: [],
        expirationMonthError: [],
        addressError: [],
        postalCodeError: [],
        phoneError: [],
        errors: [],
        creditCardError: [],
        postalCodeErrors: [],
        cityErrors: [],
        stateErrors: [],
        nameError: [],
        domicileErrors: [],
        countryErrors: [],
        confirmError: [],
        errorFormMounted: [],
        securityCodeError: [],
        paymentInstallmentsError: [],
        paymentsInstallment: [],
        paymentsInstallmentItems: [
          { name: this.$t('monthly'), type: 1 },
          { name: this.$t('halfYearly'), type: 6 },
          { name: this.$t('annual'), type: 12 }
        ],
        lastName: null,
        paymentInstallment: 1,
        birthDate: null,
        userName: null,
        postal_code: null,
        state: null,
        line_1: null,
        city: null,
        country: null,
        loading: false,
        load: false,
        panel: 0,
        max: 2,
        confirmPassword: null,
        cardDataDisabled: true,
        showAdd: false,
        editedItem: {},
        password: '',
        company: null,
        tab: 0,
        canContinue: false,
        paymentsInstallmentInput: '',
        addressSelected: {},
        cardForm: {},
        addressId: null,
        mercadoPago: {},
        name: null,
        identificationNumber: null,
        email: null,
        postalCode: null,
        phone: null,
        creditCard: null,
        expirationYear: null,
        expirationMonth: null,
        proskerDataVerified: false,
        securityCode: null,
        banner_img: null,
        profile_img: null,
        currentYear: null,
        paymentsMethods: [],
        userId: null,
        mp: null,
        alreadySendPaymentInfo: false
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/currentUser'
      })
    },
    created () {
      this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
      this.setImportedConstants();
      if (this.currentUser) {
        this.preloadUserInformation();
      }
      this.setInstallments();
    },
    mounted () {
      this.initServiceCheckout();
    },
    watch: {
      'creditCard': function () {
        if (!this.alreadySendPaymentInfo && this.creditCard) {
        this.trackUserData('add_payment_info', {
            ecommerce: {
              currency: this.currencyCode,
              items: [
                {
                  item_id: this.service.id,
                  item_name: this.service.name,
                  price: this.service.price,
                  quantity: 1
                }
              ]
            }
      });
      this.alreadySendPaymentInfo = true;
    }
      }
    },
    methods: {
      sendDataLayerPayment () {
        this.trackUserData('purchase', {
                ecommerce: {
                currency: this.currencyCode,
                items: [
                    {
                    item_id: this.service.id,
                    item_name: this.service.name,
                    price: this.service.price,
                    quantity: 1
                    }
                ]
                }
        });
      },
      initServiceCheckout () {
        this.mpPublicKey = this.service.user.mp_public_key;
        this.insertMPScript();
        this.trackUserData('begin_checkout', {
                ecommerce: {
                currency: this.currencyCode,
                items: [
                    {
                    item_id: this.service.id,
                    item_name: this.service.name,
                    price: this.service.price,
                    quantity: 1
                    }
                ]
                }
        });
      },
      insertMPScript () {
        const scriptTag = document.getElementById('mp-script');
        if (scriptTag) {
          scriptTag.parentNode.removeChild(scriptTag);
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'mp-script';
        script.src = 'https://sdk.mercadopago.com/js/v2';
        script.addEventListener('load', this.loadCardForm);
        document.body.appendChild(script);
      },
      setImportedConstants () {
        this.$options.AES_SECRET = AES_SECRET;
      },
      validateYear (year) {
        this.currentYear = moment().format('YY');
        return this.currentYear < year;
      },
      onlyNumber ($event) {
        return typeOnlyNumber($event);
      },
      setInstallments () {
        this.paymentsInstallment.splice(0, this.paymentsInstallment.length);
        this.paymentsInstallmentItems.forEach((type) => {
          type.value = type.type;
          type.text = type.name;
          this.paymentsInstallment.push(type);
        });
      },
      resetErrors () {
        this.postalCodeErrors = [];
        this.phoneCodeError = [];
        this.cityErrors = [];
        this.stateErrors = [];
        this.countryErrors = [];
        this.domicileErrors = [];
        this.confirmError = [];
        this.userNameError = [];
        this.lastNameError = [];
        this.nameError = [];
        this.paymentInstallmentsError = [];
        this.errors = [];
        this.documentTypeError = [];
        this.birthDateError = [];
        this.documentNumberError = [];
        this.emailError = [];
        this.paymentMethodError = [];
        this.addressError = [];
        this.expirationMonthError = [];
        this.expirationYearError = [];
        this.postalCodeError = [];
        this.phoneError = [];
        this.creditCardError = [];
        this.securityCodeError = [];
      },
      editItem (item) {
        this.editedItem = item;
        this.editedItem.isEdit = true;
        this.showAdd = true;
      },
      validateMonths (month) {
        return validateMonth(month);
      },
      newItem () {
        this.showAdd = true;
        this.editedItem.isEdit = false;
      },
      getUserAddresses () {
        const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}`;
        axios
        .get(url)
        .then(({ data }) => {
          this.userAddresses = data.data.addresses.sort((a, b) => {
            if (a.is_default > b.is_default) {
              return 1;
            }
            if (a.is_default < b.is_default) {
              return -1;
            }
            return 0;
          });
        });
      },
      onAddClose () {
        this.getUserAddresses();
        this.editedItem = {};
        this.showAdd = false;
        this.editedItem.isEdit = false;
      },
      selectAddress (address) {
        this.currentUser.addresses = this.currentUser.addresses.map((address) => {
          address.selected = false;
          return address;
        });

        address.selected = true;
        this.addressSelected = address;
        this.canContinue = true;
        this.trackUserData('add_shipping_info', {
            ecommerce: {
              currency: this.currencyCode,
              items: [
                {
                    item_id: this.service.id,
                    item_name: this.service.name,
                    price: this.service.price,
                    quantity: 1
                }
              ]
            }
      });
      },
      verifyNewUserData () {
        this.resetErrors();
        const userNameValidation =
          /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
        const passwordValidation =
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
        if (!this.name) {
          this.nameError.push(this.$t('fieldNameRequired'));
        }
        if (!this.email) {
          this.emailError.push(this.$t('fieldEmailRequired'));
        }
        if (!this.validEmail(this.email)) {
          this.emailError.push(this.$t('enterValidEmail'));
        }
        if (
          !this.identificationNumber ||
          (this.identificationNumber && this.identificationNumber.length < 8)
        ) {
          this.documentNumberError.push(this.$t('fieldDocumentNumberRequired'));
        }
        if (
          !this.creditCard ||
          (this.creditCard && this.creditCard.length < 15)
        ) {
          this.creditCardError.push(this.$t('fieldCreditCardRequired'));
        }
        if (!this.paymentInstallment) {
          this.paymentInstallmentsError.push(
            this.$t('fieldInstallmentsRequired')
          );
        }
        if (!this.expirationMonth) {
          this.expirationMonthError.push(this.$t('fieldExpirationDateRequired'));
        }
        if (!this.validateMonths(this.expirationMonth)) {
          this.expirationMonthError.push(
            this.$t('fieldExpirationDateNotCorrect')
          );
        }
        if (!this.expirationYear || !this.validateYear(this.expirationYear)) {
          this.expirationYearError.push(this.$t('fieldExpirationDateRequired'));
        }
        if (!this.securityCode) {
          this.securityCodeError.push(this.$t('fieldSecurityCodeRequired'));
        }
        if (!this.password) {
          this.errors.push(this.$t('passwordRequired'));
        }
        if (this.password && !this.password.match(passwordValidation)) {
          this.errors.push(this.$t('invalidPassword'));
        }
        if (this.password !== this.confirmPassword) {
          this.confirmError.push(this.$t('passwordsMustMatch'));
        }
        if (!this.birthDate) {
          this.birthDateError.push(this.$t('fieldBirthDateRequired'));
        }
        if (!this.lastName) {
          this.lastNameError.push(this.$t('fieldLastNameRequired'));
        }
        if (!this.phone) {
          this.phoneError.push(this.$t('fieldPhoneNumberRequired'));
        }
        if (!this.phoneInternationalInfos.code) {
          this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
        }
        if (this.phone && this.phone.length < 9) {
          this.phoneError.push(this.$t('fieldPhoneNumberAtLeast9Characters'));
        }
        if (!this.validatePhone(this.phone, this.phoneInternationalInfos)) {
          this.phoneError.push(this.$t('enterValidPhone'));
        }
        if (!this.userName) {
          this.userNameError.push(this.$t('invalidUser'));
        }
        if (this.userName && !this.userName.match(userNameValidation)) {
          this.userNameError.push(this.$t('invalidUser'));
        }
        if (!this.line_1) {
          this.domicileErrors.push(this.$t('fieldDomicileRequired'));
        }
        if (!this.state) {
          this.stateErrors.push(this.$t('fieldStateRequired'));
        }
        if (!this.postal_code) {
          this.postalCodeErrors.push(this.$t('fieldPostalCodeRequired'));
        }
        if (!this.city) {
          this.cityErrors.push(this.$t('fieldCityRequired'));
        }
        if (!this.country) {
          this.countryErrors.push(this.$t('fieldCountryRequired'));
        }

        return (
          this.name &&
          this.email &&
          this.validEmail(this.email) &&
          this.identificationNumber &&
          this.creditCard &&
          this.expirationMonth &&
          this.expirationYear &&
          this.securityCode &&
          this.paymentInstallment &&
          this.lastName &&
          this.phone &&
          this.validatePhone(this.phone, this.phoneInternationalInfos) &&
          this.userName &&
          this.userName.match(userNameValidation) &&
          this.password &&
          this.password.match(passwordValidation) &&
          this.birthDate &&
          this.phoneInternationalInfos.code &&
          this.password === this.confirmPassword &&
          this.country &&
          this.city &&
          this.postal_code &&
          this.state &&
          this.line_1 &&
          this.validateMonths(this.expirationMonth) &&
          this.phone.length > 9 &&
          this.creditCard.length > 14 &&
          this.identificationNumber.length > 7 &&
          this.validateYear(this.expirationYear)
        );
      },
      getDateFormat (date) {
        const originalDate = new Date(date);
        return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
      },
      loadCardForm () {
        this.mercadopago = new window.MercadoPago(this.mpPublicKey, {
          locale: this.$i18n.locale
        });
        this.cardForm = this.mercadopago.cardForm({
          amount: document.getElementById('total-cost').innerHTML,
          autoMount: true,
          form: {
            id: 'form-checkout-service',
            cardholderName: {
              id: 'form-checkout-service__cardholderName'
            },
            cardholderEmail: {
              id: 'form-checkout-service__cardholderEmail'
            },
            cardNumber: {
              id: 'form-checkout-service__cardNumber'
            },
            cardExpirationMonth: {
              id: 'form-checkout-service__cardExpirationMonth'
            },
            cardExpirationYear: {
              id: 'form-checkout-service__cardExpirationYear'
            },
            securityCode: {
              id: 'form-checkout-service__securityCode'
            },
            identificationType: {
              id: 'form-checkout-service__identificationType'
            },
            installments: {
              id: 'form-checkout-service__installments'
            },
            identificationNumber: {
              id: 'form-checkout-service__identificationNumber'
            },
            issuer: {
              id: 'form-checkout-service__issuer'
            }
          },
          callbacks: {
            onFormMounted: (error) => {
              if (error) {
                this.errorFormMounted.push(error);
              }
            },
            onError: (errors) => {
              errors.map((error) => {
                if (error.message.includes('cardNumber')) {
                  this.creditCardError.push(this.$t('fieldCreditCardRequired'));
                }
                if (error.message.includes('cardExpirationMonth')) {
                  this.expirationMonthError.push(
                    this.$t('fieldExpirationMonthRequired')
                  );
                }
                if (error.message.includes('cardExpirationYear')) {
                  this.expirationYearError.push(
                    this.$t('fieldExpirationYearRequired')
                  );
                }
              });
            },
            onSubmit: (event) => {
              event.preventDefault();
            }
          }
        });
      },
      preloadUserInformation () {
        if (this.currentUser.first_name && this.currentUser.last_name) {
          this.name = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
        }

        if (this.currentUser.email) {
          this.email = this.currentUser.email;
        }
        if (this.currentUser.phone_number) {
          this.phone = this.currentUser.phone_number;
        }
        if (this.currentUser.phone_code) {
          const foundedInternationalInfos = getPhoneCodes().find((phoneCode) => phoneCode.code === parseInt(this.currentUser.phone_code));
          this.phoneInternationalInfos = foundedInternationalInfos || defaultPhoneInternationalInfos;
        } else {
          this.phoneInternationalInfos = defaultPhoneInternationalInfos;
        }
      },
      validEmail (email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      validatePhone (phone, phoneInternationalInfos) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil.isValidNumberForRegion(phoneUtil.parse(removeMaskFromPhoneNumber(phone), phoneInternationalInfos.country), phoneInternationalInfos.country)
      },
      verifyCardData () {
        this.resetErrors();
        if (!this.name) {
          this.nameError.push(this.$t('fieldNameRequired'));
        }
        if (!this.email) {
          this.emailError.push(this.$t('fieldEmailRequired'));
        }
        if (!this.validEmail(this.email)) {
          this.emailError.push(this.$t('enterValidEmail'));
        }
        if (
          !this.creditCard ||
          (this.creditCard && this.creditCard.length < 15)
        ) {
          this.creditCardError.push(this.$t('fieldCreditCardRequired'));
        }
        if (!this.paymentInstallment) {
          this.paymentInstallmentsError.push(
            this.$t('fieldInstallmentsRequired')
          );
        }
        if (!this.expirationMonth) {
          this.expirationMonthError.push(this.$t('fieldExpirationDateRequired'));
        }
        if (!this.validateMonths(this.expirationMonth)) {
          this.expirationMonthError.push(
            this.$t('fieldExpirationDateNotCorrect')
          );
        }
        if (!this.securityCode) {
          this.securityCodeError.push(this.$t('fieldSecurityCodeRequired'));
        }
        if (!this.expirationYear || !this.validateYear(this.expirationYear)) {
          this.expirationYearError.push(this.$t('fieldExpirationDateRequired'));
        }
        if (
          !this.identificationNumber ||
          (this.identificationNumber && this.identificationNumber.length < 8)
        ) {
          this.documentNumberError.push(this.$t('fieldDocumentNumberRequired'));
        }
        return (
          this.name &&
          this.email &&
          this.validEmail(this.email) &&
          this.identificationNumber &&
          this.creditCard &&
          this.expirationMonth &&
          this.expirationYear &&
          this.securityCode &&
          this.paymentInstallment &&
          this.validateMonths(this.expirationMonth) &&
          this.creditCard.length > 14 &&
          this.identificationNumber.length > 7 &&
          this.validateYear(this.expirationYear)
        );
      },
      verifyUserData () {
        if (!this.phone) {
          this.phoneError.push(this.$t('fieldPhoneNumberRequired'));
        }
        if (!this.phoneInternationalInfos.code) {
          this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
        }
        if (!this.validatePhone(this.phone, this.phoneInternationalInfos)) {
          this.phoneError.push(this.$t('enterValidPhone'));
        }
        return (
          this.phone &&
          this.validatePhone(this.phone, this.phoneInternationalInfos) &&
          this.phoneInternationalInfos.code
        );
      },
      nextStep () {
        if (this.verifyCardData()) {
          document.getElementById('form-checkout-service__submit').click();
          this.cardDataDisabled = false;
          this.panel = 1;
        }
      },
      getInvoiceCommonPayloadData () {
        return {
          client_phone_number: removeMaskFromPhoneNumber(this.phone),
          phone_code: this.phoneInternationalInfos.code,
          documentType: this.cardForm.getCardFormData().identificationType,
          document: this.cardForm.getCardFormData().identificationNumber,
          cardToken: this.cardForm.getCardFormData().token,
          amount: this.cardForm.getCardFormData().amount,
          installments: 1,
          payment_method_id: this.cardForm.getCardFormData().paymentMethodId
        };
      },
      payService () {
        let data = this.getInvoiceCommonPayloadData();
        const selectedServiceId = this.$route.query.selectedServiceId ? this.$route.query.selectedServiceId : this.service.id;
        const selectedStartDate = this.$route.query.start_date ? this.$route.query.start_date : this.selectedDateTime.date;

        data.user_id = this.currentUser.id;
        data.address_id = this.addressSelected.id;
        data.service_id = selectedServiceId;
        data.start_date = selectedStartDate;
        data.start_time = this.selectedDateTime.time;

        this.sendInvoiceRequest(
          `${process.env.VUE_APP_BASE_URL}/api/mp/payment/invoice`,
          data,
          { name: 'social.user-services' }
        );
      },
      sendInvoiceRequest (url, data, params) {
        this.loading = true;
        axios
          .post(url, data)
          .then((response) => {
            if (response.data.success) {
              this.$swal.fire({
                title: this.$t('awesome'),
                text: this.$t('checkoutCreateSuccessfully'),
                confirmButtonColor: '#19AD93'
              });
              this.sendDataLayerPayment();
              this.$router.push(params);
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      },
      payOrder () {
        if (this.verifyCardData() && this.verifyUserData()) {
            this.payService();
        }
      }
    }
  };
  </script>
